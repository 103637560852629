<template>
    <div 
        v-if="offerId"
        class="flex flex-col content-start h-full w-full">
        
            
        <div class="flex-none bg-gray-200 flex items-center pt-4 px-4 pb-8">
            <button 
                class="button transparent mr-2"
                @click="$emit('close')">
                 
                <i class="fas fa-times " />
            </button>
            
            <h2 class="text-black m-0 flex-grow">{{$t('offers.offerDetails')}}</h2>
            
            <router-link 
                :disabled="loading"
                class="button transparent link ml-2"
                :to="{ 
                    name: 'offers-create', 
                    params: {
                        offerId: offerId
                    },
                    query: {
                        duplicate: true,
                    }
                }">
                
                <i class="fas fa-copy " />
                <span class="hidden md:inline ml-2">{{$t('general.duplicate')}}</span>
            </router-link>
            
            
            <button 
                v-if="!offer.deletedAt"
                :disabled="loading"
                class="button transparent link ml-2"
                :class="{'loading' : loading == 'delete'}"
                @click.prevent="deleteOffer()">
                
                <i class="fas fa-trash " />
                <span class="hidden md:inline ml-2">{{$t('general.delete')}}</span>
            </button>
            
            <router-link 
                v-if="offer.status == 'active' || offer.status == 'draft'"
                :disabled="loading"
                class="button submit ml-4"
                :class="{'loading' : loading == 'update'}"
                :to="{ 
                    name: 'offers-create', 
                    params: {
                        offerId: offerId
                    }
                }">
                
                <i class="fas fa-edit mr-2" />
                {{$t('general.edit')}}
            </router-link>
        </div>
        
        <div class="flex-none bg-gray-200 px-6">
            <div class="flex">
                <div 
                    class="
                        cursor-pointer py-2 px-4
                        md:py-3
                        md:px-6
                    "
                    :class="section == 'details' ? 'bg-white text-black border-t-2 border-cyan' : 'hover:text-black'"
                    @click="section = 'details'">
                    
                    {{$t('offers.details')}}
                </div>
                <div 
                    class="
                        cursor-pointer py-2 px-4
                        md:py-3
                        md:px-6
                    "
                    :class="section == 'view' ? 'bg-white text-black border-t-2 border-cyan' : 'hover:text-black'"
                    @click="section = 'view'">
                    
                    {{$t('offers.view')}}
                </div>
                <div 
                    class="
                        cursor-pointer py-2 px-4
                        md:py-3
                        md:px-6
                    "
                    :class="section == 'messages' ? 'bg-white text-black border-t-2 border-cyan' : 'hover:text-black'"
                    @click="section = 'messages'">
                    
                    {{$t('offers.messages.messages')}}
                </div>
                <div 
                    class="
                        cursor-pointer py-2 px-4
                        md:py-3
                        md:px-6
                    "
                    :class="section == 'tracking' ? 'bg-white text-black border-t-2 border-cyan' : 'hover:text-black'"
                    @click="section = 'tracking'">
                    
                    {{$t('offers.tracking.tracking')}}
                </div>
            </div>
        </div>
        
        <div 
            v-if="loading"
            class="absolute inset-0 bg-white flex items-center justify-center">
            
            <div class="spinner"></div>
        </div>
        <div 
            v-else
            class="flex-grow overflow-hidden flex flex-col h-full w-full">
            
            <div 
                v-if="section == 'details'"
                class="p-8 overflow-auto">
                
                <div 
                    class="mb-8 border-b border-dashed border-gray-300 pb-8"
                    v-if="offer.status != 'draft'">
                    
                    <h2>{{$t('offers.progress')}}</h2>
                    
                    <div class="flex items-start mt-8">
                        <div class="w-4 h-4 bg-gray-300 rounded-full mr-4 mt-1 flex-none" />
                        <div class="w-32 flex-none">{{$t('offers.saved')}}</div>  
                        
                        <div class="flex-grow">
                            <div v-if="offer.sendMethod == 'save'">
                                {{$t('offers.savedText')}}
                            </div>
                            <div v-else-if="offer.sendMethod == 'email'">
                                {{$t('offers.savedEmailText')}}
                            </div>
                            <div v-else-if="offer.sendMethod == 'sms'">
                                {{$t('offers.savedSmsText')}}
                            </div>
                            
                            <div>{{ offer.updatedAt | moment('lll') }}</div>
                            <div class="opacity-50">{{ offer.updatedAt | moment('from') }}</div>
                        </div>
                    </div>
                    
                    <div 
                        v-if="offer.followedupAt"
                        class="flex items-start mt-4">
                        
                        <div class="w-4 h-4 bg-gray-300 rounded-full mr-4 mt-1 flex-none" />
                        <div class="w-32 flex-none">{{$t('offers.followedUp')}}</div>
                        <div class="flex-grow">
                            <div v-if="offer.followupMethod == 'email'">
                                {{$t('offers.sentANotificationByEmail')}}
                            </div>
                            <div v-else-if="offer.followupMethod == 'sms'">
                                {{$t('offers.sentANotificationBySms')}}
                            </div>
                            <div>{{ offer.followedupAt | moment('lll') }}</div>
                            <div class="opacity-50">{{ offer.followedupAt | moment('from') }}</div>
                        </div>
                    </div>
                    
                    <div 
                        v-if="offer.acceptedAt"
                        class="flex items-start mt-4">
                        
                        <div class="w-4 h-4 bg-green-500 rounded-full mr-4 mt-1 flex-none" />
                        <div class="w-32 flex-none">{{$t('offers.statuses.accepted')}}</div>
                        <div class="flex-grow">
                            <div>{{ offer.acceptedAt | moment('lll') }}</div>
                            <div class="opacity-50">{{ offer.acceptedAt | moment('from') }}</div>
                        </div>
                    </div>
                    <div 
                        v-else-if="offer.declinedAt"
                        class="flex items-start mt-4">
                        
                        <div class="w-4 h-4 bg-red-500 rounded-full mr-4 mt-1 flex-none" />
                        <div class="w-32 flex-none">{{$t('offers.statuses.declined')}}</div>
                        <div class="flex-grow">
                            <div>{{ offer.declinedAt | moment('lll') }}</div>
                            <div class="opacity-50">{{ offer.declinedAt | moment('from') }}</div>
                        </div>
                    </div>
                    <div 
                        v-else-if="offer.deletedAt"
                        class="flex items-start mt-4">
                        
                        <div class="w-4 h-4 bg-gray-500 rounded-full mr-4 mt-1 flex-none" />
                        <div class="w-32 flex-none">{{$t('offers.statuses.deleted')}}</div>
                        <div class="flex-grow">
                            <div>{{ offer.deletedAt | moment('lll') }}</div>
                            <div class="opacity-50">{{ offer.deletedAt | moment('from') }}</div>
                        </div>
                    </div>
                    <div v-else>
                        <div 
                            v-if="offer.followupAt"
                            class="flex items-start mt-4">
                            
                            <div class="w-4 h-4 border border-gray-400 bg-white rounded-full mr-4 mt-1 flex-none" />
                            <div class="w-32 flex-none">{{$t('offers.followUp')}}</div>
                            <div class="flex-grow">
                                <div v-if="offer.followupMethod == 'email'">
                                    {{$t('offers.willSendANotificationByEmail')}}
                                </div>
                                <div v-else-if="offer.followupMethod == 'sms'">
                                    {{$t('offers.willSendANotificationBySms')}}
                                </div>
                                <div>{{ offer.followupAt | moment('lll') }}</div>
                                <div class="opacity-50">{{ offer.followupAt | moment('from') }}</div>
                            </div>
                        </div>
                        
                        
                        <div 
                            v-if="offer.expireAt"
                            class="flex items-start mt-4">
                            
                            <div 
                                class="w-4 h-4 rounded-full mr-4 mt-1 flex-none" 
                                :class="offer.expireAt > Date.now() ? ' bg-white border border-gray-400' : 'bg-gray-300' "
                            />
                            
                            <div class="w-32 flex-none">{{ offer.expireAt > Date.now() ? $t('offers.expire') : $t('offers.expired')}}</div>
                            
                            <div class="flex-grow">
                                <div>{{ offer.expireAt | moment('lll') }}</div>
                                <div class="opacity-50">{{ offer.expireAt | moment('from') }}</div>
                            </div>
                        </div>
                        <div 
                            v-else
                            class="flex items-start mt-4">
                            
                            <div 
                                class="w-4 h-4 rounded-full mr-4 mt-1 flex-none" 
                                :class="hibernationInfo.hibernateAt > Date.now() ? ' bg-white border border-gray-400' : 'bg-gray-300' "
                            />
                            <div class="w-32 flex-none">{{ hibernationInfo.hibernateAt > Date.now() ? $t('offers.hibernate') : $t('offers.hibernated')}}</div>
                            
                            <div class="flex-grow">
                                <div>{{ hibernationInfo.hibernateAt | moment('lll') }}</div>
                                <div class="opacity-50">{{ hibernationInfo.hibernateAt | moment('from') }}</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div class="mb-8 border-b border-dashed border-gray-300 pb-8">
                    <h2>{{$t('customers.customer')}}</h2>
                    
                    <div class="mt-4">
                        <div 
                            v-if="customer.isCompany"
                            class="grid grid-cols-2 gap-8 mb-4">
                            
                            <div v-if="customer.name" >
                                <h4 class="">{{$t('customers.companyName')}}</h4>
                                <div class="">{{customer.name}}</div>
                            </div>
                            
                            <div v-if="customer.organizationNumber" >
                                <h4 class="">{{$t('customers.organizationNumber')}}</h4>
                                <div class="">{{customer.organizationNumber}}</div>
                            </div>
                        </div>
                        
                        <div class="grid grid-cols-2 gap-16">
                            <div>
                                <div 
                                    v-if="offer.contact.name"
                                    class="mb-4">
                                    
                                    {{offer.contact.name }}
                                </div>
                                <div 
                                    v-if="offer.contact.email"
                                    class="mb-4 truncate w-full overflow-hidden">
                                    
                                    <a 
                                        class="link" 
                                        :href="'tel:'+offer.contact.email" >
                                        
                                        <i class="far fa-envelope mr-2 text-sm" />
                                        {{offer.contact.email}}
                                    </a>
                                </div>
                                <div 
                                    v-if="offer.contact.phone" 
                                    class="truncate w-full overflow-hidden">
                                    
                                    <a 
                                        class="link" 
                                        :href="'tel:'+offer.contact.phone" >
                                        
                                        <i class="fas fa-phone mr-2 text-sm" />
                                        {{offer.contact.phone}}
                                    </a>
                                </div>
                            </div>
                            
                            <div v-if="customer.street">
                                <!-- <h4>Address</h4> -->
                                {{customer.street}}<br>
                                {{customer.postcode}} {{customer.city}}<br>
                                {{customer.country}}
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                <div class="mb-8 pb-8">
                    <h2>{{$t('products.products')}}</h2>
                    
                    <div 
                        class="flex border-b border-dashed border-gray-300 py-4"
                        v-for="(product,index) in offer.products"
                        :key="index">
                        
                        <img 
                            v-if="product.coverUrl" 
                            :src="product.coverUrl" 
                            class="w-24 h-24 object-cover flex-none mr-8 rounded"
                        />
                        
                        <div class="flex-grow flex flex-col justify-center">
                            <div class="text-black">{{product.name}}</div>
                            
                            <div class="flex">
                                <div class="flex-grow">
                                    <span>{{ product.priceFormated }}</span> x {{product.qty}}
                                    <span v-if="product.discount"> ( -{{product.discount}} %)</span>
                                </div>
                                
                                <span>{{ product.priceQuantityFormated }}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="flex text-black mt-4">
                        <div class="w-1/2 text-left">
                            {{$t('offers.total')}}
                            <span v-if="offer.priceTotalAdjustDiscount">(-{{offer.priceTotalAdjustDiscountRounded}}%)</span>
                        </div>
                        <div class="w-1/2 text-right">{{ offer.priceTotalFormated }}</div>
                    </div>
                </div>
                
                <div class="text-center py-12 opacity-50 hover:opacity-100">
                    <div>{{$t('offers.uniqueIdentifier')}}</div>
                    <h1>{{offerId}}</h1>
                </div>
            </div>
            <div 
                v-if="section == 'view'"
                class="flex h-full overflow-auto relative">
                
                <div 
                    v-show="iframeLoading"
                    class="absolute inset-0 flex items-center justify-center">
                    
                    <div class="spinner"></div>
                </div>
                <iframe 
                    v-show="!iframeLoading"
                    @load="iframeLoading = false"
                    :src="'/d/1/i.html?a='+accountId+'&o='+offerId" 
                    class="w-full h-full bg-white"
                />
            </div>
            <div 
                v-else-if="section == 'messages'"
                class="flex-none flex flex-col h-full overflow-hidden relative">
                
                <div class="flex-grow overflow-auto p-8">
                    
                    <div 
                        v-if="messagesSorted && !messagesSorted.length"
                        class="flex-grow h-full w-full flex items-center justify-center flex-col text-gray-500 ">
                        
                        <i class="fas fa-envelope-open-text text-gray-300 text-6xl mb-4" />
                        {{$t('offers.messages.noMessages')}}
                    </div>
                    
                    <div 
                        v-for="message in messagesSorted"
                        :key="message.messageId">
                        
                        <!-- <i 
                            class="fas fa-check-circle text-cyan m-1" 
                            :class="message.from == 'support' ? 'float-left' : 'float-right'"
                            v-if="message.readAt"
                        /> -->
                        
                        <div 
                            v-if="message.from == 'support'"
                            class="message text-left">
                            
                            <div class="text-sm text-gray-500 text-left mb-1">
                                {{$t('offers.messages.you')}}
                                <!-- <span class="on-message-hover opacity-50">{{new Date(message.receivedAt).toString().split('GMT')[0]}}</span> -->
                                <span class="on-message-hover opacity-50">{{message.receivedAt | moment('lll')}}</span>
                            </div>
                            <p 
                                class="bg-gray-200 py-3 px-4 mb-4 rounded-lg inline-block rounded-tl-none mr-12"
                                v-html="message.text.replace(/(?:\r\n|\r|\n)/g, '<br />')">
                            </p>
                        </div>
                        <div 
                            v-else
                            class="message text-right">
                            
                            <div class="text-sm text-gray-500 text-right mb-1">
                                <!-- <span class="on-message-hover opacity-50">{{new Date(message.receivedAt).toString().split('GMT')[0]}}</span> -->
                                <span class="on-message-hover opacity-50">{{message.receivedAt | moment('lll')}}</span>
                                {{$t('offers.messages.customer')}}
                            </div>
                            <p 
                                class="bg-gray-200 py-3 px-4 mb-4 rounded-lg inline-block rounded-tr-none ml-12 text-left"
                                :class="message.readAt ? '' : 'text-black'"
                                v-html="message.text.replace(/(?:\r\n|\r|\n)/g, '<br />')">
                            </p>
                        </div>
                    </div>
                    <div id="messagesEnd"></div>
                </div>
                <div v-if="offer.status == 'draft' || offer.status == 'deleted'">
                    <!-- Not allowed to send messages in draft of deleted -->
                </div>
                <div 
                    v-else
                    class="flex-none relative">
                    
                    <div 
                        v-if="showNewMessageNotification"
                        class="-mt-12 absolute w-full text-center">
                        
                        <div 
                            @click="scrollIntoView()" 
                            class="inline-block bg-gray-600 text-white p-1 px-3 rounded cursor-pointer hover:bg-gray-700 ">
                            
                            {{$t('offers.messages.newMessages')}}
                        </div>
                    </div>
                    
                    <textarea 
                        class="bg-white p-8 py-4 w-full h-24 pr-24 border-0 border-t border-gray-400 rounded-none"
                        :placeholder="$t('offers.messages.writeAMessage')"
                        :value='messageNew'
                        @input="evt=>messageNew=evt.target.value">
                    </textarea>
                    
                    <button
                        class="absolute top-0 right-0 m-4 text-white bg-cyan px-4 py-2 text-center rounded"
                        :class="messageNew == '' ? 'opacity-0' : ''" 
                        :disabled="messageNew == '' ? true : false"
                        @click="sendMessage()">
                        
                        {{$t('offers.messages.send')}}
                    </button>
                </div>
                <div 
                    v-if="messagesUnread.length != 0"
                    class="p-8 bg-gray-100 border-b border-gray-300">
                    
                    <p>{{$t('offers.messages.messagesUnreadText')}}</p>
                    
                    <button
                        class="text-white px-4 py-2 bg-cyan text-center rounded"
                        @click="markAllAsRead()">
                        <i class="fas fa-check-circle text-white mr-1" /> {{$t('offers.messages.markAllAsRead')}}
                    </button>
                </div>
            </div>
            <div 
                v-else-if="section == 'tracking'"
                class="flex-none flex flex-col h-full overflow-y-auto relative p-8">
                
                <div 
                    v-if="trackings && !trackings.length"
                    class="flex-grow h-full w-full flex items-center justify-center flex-col text-gray-500 ">
                    
                    <i class="fas fa-eye-slash text-gray-300 text-6xl mb-4" />
                    {{$t('offers.tracking.noVisitsHasBeenRegisteredYet')}}
                </div>
                <div v-else>
                    <div 
                        v-for="(track, index) in trackings"
                        :key="track.trackingId"
                        class="w-full border-b border-dashed pb-4 mb-4 ">
                        
                        <div 
                            class="flex my-8"
                            :title="track.trackingId">
                            
                            <div class="flex-grow">
                                <span class="text-black">{{$t('offers.tracking.sessionCount')}} {{trackings.length - (index)}}</span>
                                <!-- <strong class="text-xl mb-4">Session</strong> -->
                                <!-- <strong class="text-xl mb-4">{{tracking.length - (index)}}</strong> -->
                            </div>
                        </div>
                        
                        <div class="flex mb-4">
                            <div class="w-32">
                                {{$t('offers.tracking.started')}}
                            </div>
                            <div class="flex-grow">
                                {{ track.initAt || track.updatedAt | moment('lll') }}
                                <div class="opacity-50">
                                    {{ track.initAt || track.updatedAt | moment('from') }}
                                </div>
                            </div>
                        </div>
                        
                        <div class="flex mb-4">
                            <div class="w-32">
                                {{$t('offers.tracking.lastActivity')}}
                            </div>
                            <div class="flex-grow">
                                 {{ track.updatedAt | moment('lll') }}
                                 <div class="opacity-50">
                                     {{ track.updatedAt | moment('from') }}
                                 </div>
                            </div>
                        </div>
                        
                        <div class="flex mb-4">
                            <div class="w-32">
                                {{$t('offers.tracking.timeSpent')}}
                            </div>
                            <div class="flex-grow">
                                 {{ (track.visibleSeconds *1000) || 0 | duration('humanize') }}
                                 <div class="opacity-50">
                                     {{ track.visibleSeconds || 0 }} {{$t('offers.tracking.seconds')}}
                                 </div>
                            </div>
                        </div>
                        
                        <div class="flex mb-4">
                            <div class="w-32">
                                {{$t('offers.tracking.deviceType')}}
                            </div>
                            <div class="flex-grow">
                                <i 
                                    class="fas w-6 h-6 mr-2"
                                    :class="
                                        [
                                            track.device == 'mobile' ? 'fa-mobile-alt' : '',
                                            track.device == 'tablet' ? 'fa-tablet-alt' : '',
                                            track.device == 'desktop' || !track.device ? 'fa-desktop' : ''
                                        ]
                                    "
                                />
                                <span class="capitalize">{{track.device}}</span>
                            </div>
                        </div>
                        
                        <div class="flex">
                            <div class="w-32">
                                {{$t('offers.tracking.userAction')}}
                            </div>
                            <div class="flex-grow">
                                <div v-if="track.acceptedAt">
                                    <div class="text-white rounded-sm inline-block bg-green-500 px-3 py-1 mb-2">
                                        {{$t('offers.statuses.accepted')}}
                                    </div>
                                    <div>{{ track.acceptedAt | moment('lll') }}</div>
                                    <div class="opacity-50">{{ track.acceptedAt | moment('from') }}</div>
                                </div>
                                <div v-else-if="track.declinedAt">
                                    <div class="text-white rounded-sm inline-block bg-red-500 px-3 py-1 mb-2">
                                        {{$t('offers.statuses.declined')}}
                                    </div>
                                    <div>{{ track.declinedAt | moment('lll') }}</div>
                                    <div class="opacity-50">{{ track.declinedAt | moment('from') }}</div>
                                </div>
                                <div v-else>
                                    <i class="fas fa-eye w-6 mr-2" />
                                    {{$t('offers.tracking.visitWithNoAction')}}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        // offer: {
        //     type: Object,
        //     required: true,
        //     default: null
        // },
        offerId: {
            type: String,
            required: true,
            default: null
        }
    },
    
    computed: {
        offer(){
            return this.$store.getters.getOffers.find(o => o.offerId == this.offerId);
        },
        
        trackings(){
            const trackings = this.$store.getters.getTrackings;
            const offerTrackings = trackings.filter(t => t.trackingId.startsWith( this.offerId ) );
            return offerTrackings;
        },
        
        messages(){
            return this.$store.getters.getMessages;
        },
        
        messagesSorted(){
            if (!this.messages) {
                return 
            }
            
            const messages = this.messages.filter( m => {
                if (m.messageId.startsWith( this.offerId )) {
                    return true;
                }
            });
            
            return messages;
        },
        
        messagesUnread(){
            return this.messagesSorted
                .filter( m => m.from != 'support')
                .filter( m => !m.readAt);
        },
        
        
        customers(){
            return this.$store.getters.getCustomers;
        },
        
        customer(){
            return this.customers.find( c => c.customerId == this.offer.customerId);
        },
        
        
        hibernationInfo(){
            let info = {
                now: new Date().getTime(),
                hibernateAt: this.offer.updatedAt + ( (this.account.hibernate.days * 86400)*1000 ),
            };
            
            info.percent =  (info.now - this.offer.updatedAt ) / (info.hibernateAt - this.offer.updatedAt ) * 100;
            info.followupPercent =  (this.offer.followupAt - this.offer.updatedAt ) / (info.hibernateAt - this.offer.updatedAt ) * 100;
            
            return info;
        },
    },
    
    data() {
        return {
            // offerId: null,
            loading: null,
            iframeLoading: true,
            section: 'details',
            messageNew: '',
            showNewMessageNotification: false,
            showMessagesContact: true,
            // tracking: null,
        }
    },
    
    watch: {
        messagesSorted(newMessages, oldMessages) {
            if (newMessages.length > oldMessages.length ) {
                this.$nextTick( () => {
                    this.scrollIntoView();
                });
            }
        },
    },
    
    methods: {
        // duplicateOffer(){
        //     const duplicatedOffer = JSON.parse( JSON.stringify( this.offer ) );
        //     console.log(duplicatedOffer);
        // },
        
        async deleteOffer(){
            try {
                this.loading = 'delete';
                const offer = await this.$store.dispatch('deleteOffer', this.offer);
                
                if (!offer.deletedAt) {
                    throw Error( this.$t('offers.problemDeleting') );
                }
            } 
            catch (e) {
                this.$notify({ 
                    type: 'error', 
                    text: e.message
                });
                
                console.error(e);
            } 
            finally {
                this.loading = null;
            }
        },
        
        closeHandler( e ){
            if (e.keyCode === 27) {
                this.$emit('close');
            }
        },
        
        isInViewport() {
            var element = document.getElementById('messagesEnd');
            if (element) {
                const rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
        },
        
        scrollIntoView(){
            var element = document.getElementById('messagesEnd');
            if (element) {
                try {
                    this.showNewMessageNotification = false;
                    element.scrollIntoView({behavior: 'smooth',inline: 'nearest'});
                } 
                catch (e) {
                    console.error(e);
                }
            }
        },
        
        async sendMessage(){
            try {
                const messageNew = {
                    from: 'support',
                    offerId: this.offerId,
                    text: this.messageNew,
                    action: 'refresh', // hardcoded so that the message allways refresh on client side.
                };
                
                const message = await this.$store.dispatch('createMessage', messageNew);
                
                if (!message.messageId) {
                    throw Error( this.$t('offers.messages.problemCreating') );
                }
                this.messageNew = '';
            } 
            catch (e) {
                this.$notify( {type: 'error', text: e.message} );
                console.error(e);
            }
        },
        
        async markAllAsRead(){
            const promises = this.messagesUnread.map( message => {
                return this.$store.dispatch('updateMessage', message);
            });
            
            const promiseAllResults = await Promise.all( promises );
            this.$notify({ type: 'success', text: this.$t('offers.messages.markedAllMessagesAsRead') });
            analytics.track( 'offerEdit markAllAsRead');
        },
        
        
        async getOffer() {
            try {
                this.loading = true;
                await this.$store.dispatch('getOffer', this.offerId);
            } 
            catch (e) {
                this.$notify( {type: 'error', text: this.$t('offers.tracking.couldNotFetchUpdatedOfferTrackings')} );
                console.error(e);
            }
            finally {
                this.loading = null;
            }
        },
    },
    
    async mounted(){
        // this.offerId = this.offer.offerId;
        // request an updated offer and return array
            // [0] = offer
            // [1] = all offer trackings
            // [2] = all offer messages
        await this.getOffer();
        window.addEventListener('keyup', this.closeHandler );
        analytics.track( 'offerEdit opened', this.offer);
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.closeHandler);
    },
}
</script>

<style lang="scss">
    .message {
        .on-message-hover {
            display: none;
        }
        
        &:hover {
            .on-message-hover{
                display: inline-block;
            }
        }
    }
</style>
